/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import { Link } from "gatsby"
import ImgDiferencial from "@/images/lp/o-essencial-para-os-pais.png"
import ImgDiferencialMob from "@/images/lp/o-essencial-para-os-pais-mob.png"
import Material from "@/components/MaterialTabs";

import { 
  oDiferencialParaOsPais,
  DDesktop,
  DMobile,
}  from '../assets/styles/ExcelenciaInternacional.styles'

import { 
  materialEscolar,
}  from '../assets/styles/MaterialTab.styles'

const EssencialDiferencial = () => {
  return (
    <Fragment>
      <section css={oDiferencialParaOsPais}>
        <div className="text-right">
          <img style={{ marginLeft: 'auto' }} css={DDesktop} src={ImgDiferencial} alt='/' />
          <img css={DMobile} src={ImgDiferencialMob} alt='/' />
        </div>
        <Container>
          <Row>
            <Col lg={1} md={1} sm={12} />
            <Col lg={11} md={11} sm={12}>
              <h2>essencial para os pais</h2>
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <h3>Fortalecendo a parceria entre escola e pais: descubra nossos serviços e otimize a participação na educação dos seus filhos!</h3>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col lg={5} md={5} sm={12}>
              <p>Toda atenção, tempo e investimento necessários à formação dos alunos servem para melhorar também a experiência e o relacionamento com os pais. Pensando nisso, disponibilizamos em nosso site uma variedade de serviços essenciais que antes exigiam a presença física na escola. Acreditamos que facilitar o acesso a esses serviços fortalece a parceria entre a escola e os pais, garantindo uma educação de qualidade e uma comunicação ágil e eficiente. Invista seu tempo onde realmente importa: na formação e no sucesso dos seus filhos. Estamos aqui para apoiá-los nessa jornada.</p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section css={materialEscolar}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Material />
            </Col>
          </Row>
        </Container>
      </section> */}
    </Fragment>
    
  );
};

export default EssencialDiferencial;
