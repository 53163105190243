import { css } from '@emotion/react'

export const materialEscolar = css`
  padding: 80px 0;
  font-family:"obviously-narrow";
  font-variation-settings: "wght" 700;
  background: #C2F57E;
  .boxContent {
    border-radius: 16px;
    background: #33535E;
    width: 730px;
    padding: 24px 56px;
    margin-left: 16px;
  }
  .react-tabs {
    display: flex;
  }
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    li {
      color: #312F2D;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      text-transform: uppercase;
      border-bottom: 1px solid #312F2D;
    }
  }
  .react-tabs__tab--selected {
    color: #3B43FF !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 470;
    line-height: 120%; /* 28.8px */
    text-transform: uppercase;
    background: transparent;
    border-color: transparent;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .boxContent{
      width: 350px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .boxContent{
      width: 350px;
    }
  }

`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: flex;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: flex;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`